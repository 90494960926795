import * as Diff from "diff";
import { Notification } from "../components/Notification/index";
export const countNewLines = (str) => {
  const paragraphPattern = /\s*[\r\n]+(?:\s*[\r\n]+)+\s*/;

  const paragraphs = str.trim().split(paragraphPattern);
  return paragraphs.length;
};

export function copyToClipboard(text) {
  if (!navigator.clipboard) {
    // Nếu không hỗ trợ, sử dụng phương thức cũ (document.execCommand)
    var textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    alert("Đã sao chép vào clipboard: " + text);
  } else {
    // Sử dụng Clipboard API nếu có sẵn
    navigator.clipboard
      .writeText(text)
      .then(function () {
        Notification("success", "Copied to clipboard");
      })

      .catch(function (err) {
        console.error("Lỗi khi sao chép vào clipboard: ", err);
      });
  }
}

export function getRandomRecommendations(array, count) {
  const shuffledArray = array.slice(); // Create a shallow copy of the original array
  let currentIndex = shuffledArray.length;
  let temporaryValue, randomIndex;

  // While there remain elements to shuffle
  while (currentIndex !== 0) {
    // Pick a remaining element
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // Swap it with the current element
    temporaryValue = shuffledArray[currentIndex];
    shuffledArray[currentIndex] = shuffledArray[randomIndex];
    shuffledArray[randomIndex] = temporaryValue;
  }

  // Get the first 'count' elements from the shuffled array
  return shuffledArray.slice(0, count);
}

export function findDifference(str1, str2) {
  const diff = Diff.diffWords(str1, str2);
  return diff;
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function replaceWord(str, oldWord, newWord) {
  const escapedOldWord = escapeRegExp(oldWord);
  const regex = new RegExp(`\\b${escapedOldWord}\\b`);
  return str.replace(regex, newWord);
}
